<template>
  <div class="content" v-loading="loadingStatus.getLoading">
    <!--    <div>
      <el-form ref="staffRef" size="medium" :inline="true" :model="staffForm">
        <el-form-item label="查询：">
          <el-input v-model="staffForm.name"></el-input>
        </el-form-item>
        <el-button type="primary" size="medium" @click="getQuery">查询</el-button>
      </el-form>
    </div>-->
    <div>
      <el-table :data="tableData" border :stripe="true" style="width: 100%">
        <!--        <el-table-column prop="gameId" label="游戏ID" align="center" width="180">-->
        <!--        </el-table-column>-->
        <el-table-column
          prop="gameName"
          label="游戏名称"
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="gameCode"
          label="游戏Code"
          align="center" 
        >
        </el-table-column>
        <el-table-column
          prop="gameVersion"
          width="100"
          align="center"
          label="游戏版本"
        >
        </el-table-column>
        <el-table-column
          prop="gamePackge"
          align="center"
          width="180"
          label="游戏打包状态"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.gamePackge == '1'">已打包</span>
            <span v-else style="color: #1D90FF;padding-left: 10px;"
              >未打包</span
            >
          </template>
        </el-table-column>
        <!-- <el-table-column prop="gameDownloadUrl" align="center" label="游戏下载路径">
        </el-table-column> -->
        <el-table-column align="center" width="140" label="操作">
          <template slot-scope="scope">
            <span v-if="scope.row.gamePackge == '1'">已打包</span>
            <span
              v-else
              style="color: #1D90FF;padding-left: 10px;cursor: pointer;"
              @click="getPack(scope.row)"
              >点击打包</span
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="page"
        background
        @size-change="handleSizeChange"
        @current-change="currentChange"
        :page-sizes="[10, 15, 20]"
        :page-size="1"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
        :current-page="page.pageNo"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import loadingStatusMixin from '@/mixins/loadingStatus';
import { promotionLinkGamesList } from '@/service/moudules/promotionLinkApi';
import { promotionLinkGamesPack } from '../../service/moudules/promotionLinkApi';
export default {
  name: 'gameManagement',
  mixins: [loadingStatusMixin],
  data() {
    return {
      staffForm: {
        name: '',
      },
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  mounted() {
    this.getTable();
  },
  methods: {
    async getTable() {
      if (this.hasLoadingState('getLoading')) return;
      try {
        this.addLoadingState('getLoading');
        let params = {
          pageNo: this.page.pageNo,
          pageSize: this.page.pageSize,
        };
        let res = await promotionLinkGamesList(params);
        if (res.code == 200) {
          this.removeLoadingState('getLoading');
          this.tableData = res.data.records;
          this.page.pageNo = res.data.current;
          this.page.total = res.data.total;
        } else {
          this.removeLoadingState('getLoading');
          this.$message.error(`${res.msg}！`);
        }
      } catch (e) {
        this.removeLoadingState('getLoading');
      } finally {
        // this.removeLoadingState("getLoading");
      }
    },
    // 查询
    getQuery() {},
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.page.pageNo = 1;
      this.getTable();
    },
    currentChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getTable();
    },
    async getPack(row) {
      this.$confirm('确认要该游戏打包?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        let params = {
          gameId: row.gameId,
          downloadUrl: row.gameDownloadUrl,
          gameVersion: row.gameVersion,
        };
        let res = await promotionLinkGamesPack(params);
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '打包成功!',
          });
          this.getTable();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.content {
  padding: 20px;
  margin: 10px;
  border: 1px solid #dcdfe6;
  text-align: initial;
  background-color: white;
  .page {
    text-align: right;
    margin-top: 15px;
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar {
    width: 1px;
    height: 10px;
  }
  ::v-deep
    .el-table--scrollable-x
    .el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
  }
  ::v-deep
    .el-table--scrollable-x
    .el-table__body-wrapper::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  ::v-deep .el-table__header th.el-table__cell {
    height: 50px;
    background-color: #fafafa;
    color: #333333;
    padding: 0;
  }
}
</style>
